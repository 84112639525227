<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <form
      class="modal-card"
      @submit.prevent="onSubmit"
    >
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">Agregar Categoría</p>
      <button class="delete" aria-label="close" type="reset" @click="close()"></button>
    </header>
    <section class="modal-card-body">
      <div class="columns is-multiline is-centered">
        <div class="column is-11 field group-field">
          <label class="label">Categoría</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="category"
              name="text"
              v-validate="'required'"
              data-vv-as="de categoria"
              :class="{'is-danger': vverrors.has('text')}"
            >
            <p class="help is-danger"> {{vverrors.first('text')}} </p>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        :disabled="loading"
        class="button modal-cancel-button"
        type="reset"
        @click="close"
      >
        Cancelar
      </button>
      <button
        class="button button-givu"
        :class="{'is-loading': loading}"
        :disabled="loading"
        type="submit"
      >
        Guardar
      </button>
    </footer>
  </form>
</div>
</template>
<script>
const regexNoLetters = /[^A-Za-zñÑáéíóúÁÉÍÓÚ\s]/g
export default {
  name: 'ModalAddCategory',
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      error: false,
      loading: false,
      category: null
    }
  },
  beforeMount () {
  },
  methods: {
    async onSubmit () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        await this.action(this.category)
        this.loading = false
      }
    },
    async onlyText (text) {
      let validText = text.replace(regexNoLetters, '').replace('  ', ' ')
      if (validText === ' ') validText = ''
      return validText
    }
  }
}
</script>

<style scoped>
  .optional {
    font-size:10px;
  }
  .group-field {
    margin-top: 1px;
    margin-bottom: 1px;
  }
</style>
